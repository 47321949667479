import React, { Component } from 'react';
// - STYLES
import '../assets/scss/animate.css'; // ANIMATE CSS
import '../assets/scss/normalize.scss'; // RESET
import '../assets/scss/styles_devices.scss'; // GLOBAL 

import LangToggle from './LangToggle';

// LOGOS ASSETS PNG
import LogoBoulogne from '../assets/png/logo_partenaires_boulogne.png';
import LogoCa from '../assets/png/logo_partenaires_ca.png';
import LogoHonda from '../assets/png/logo_partenaires_honda.png';
import LogoTurtleBeach from '../assets/png/logo_partenaires_tutlebeach.png';
import LogoSodebo from '../assets/png/logo_partenaires_sodebo.png';


// LIBS
import { Cart } from 'react-bytesize-icons';
import { ReactSVG } from 'react-svg';
import Rodal from 'rodal';

// IMPORTS SVG LOGO
import Discord from '../assets/svg/icon_social_discord.svg';
import Facebook from '../assets/svg/icon_social_facebook.svg';
import Instagram from '../assets/svg/icon_social_instagram.svg';
import Linkedin from '../assets/svg/icon_social_linkedin.svg';
import Tiktok from '../assets/svg/icon_social_tiktok.svg';
import Twitch from '../assets/svg/icon_social_twitch.svg';
import Twitter from '../assets/svg/icon_social_twitter.svg';
import Youtube from '../assets/svg/icon_social_youtube.svg';
import Threads from '../assets/svg/icon_social_threads.svg';

export default class DevicesLayout extends Component {

    state = {
        data: [],     // on stockera le data recu dans notre state
        visible: false,
        // -- LANG
        lang: "", // DEFAULT BASED ON BROWSER LANG PROPERTY
        accueilHeadline: "",
        accueilSubline: "",
        accueilTrophyCta: "",
        presHeadline: "",
        presSubline1: "",
        presSubline2: "",
        presH2: "",
        prodH3:"",
        prodH2:"",
        prodCta:"",
        prodP:"",
        legalHeadline1:"",
        legal1a:"",
        legal1b:"",
        legal1c:"",
        legal1d:"",
        legalHeadline2:"",
        legal2a:"",
        legal2b:"",
        legalHeadline3:"",
        legal3a:"",
        legal3b:"",
        legal3c:"",
        legalHeadline4:"",
        legal4a:"",
        legal4b:""
    }

    componentDidMount() {
        // DEFINE LANG
        let defaultLang;
        if(navigator.language === "fr") {
        defaultLang = "fr"
        } else{
        defaultLang = "en"
        }
        this.setState({lang: defaultLang})

        // INIT LANG METHOD TO LOAD OUR LANG TEXTS VARIABLES
        this.switchLang(); 

        // GET JSON SCORES DATA ( PUBLIC/DATA/SCORES.JSON )
        fetch('./data/scores.json')
        .then(response => response.json())
        .then(data => this.setState({ data }));
    }

    show() {
        this.setState({ visible: true });
    }
     
    hide() {
        this.setState({ visible: false });
    }

    switchLang = () => {
        if (this.state.lang === 'fr') {
          this.setState({
            lang: "en",
    
            // NAVIGATION
            navHome:"Home",
            navOverview:"Overview",
            navResults: "Results",
    
            // ACCUEIL
            accueilHeadline: "The first socially responsible professional esports club",
            accueilSubline: "French esports brand with a mission, GameWard brings together professional players and content creators with the aim of inspiring the future",
            accueilTrophyCta: "Latest results",

            // PRESENTATION
            presHeadline: "Guide esports in the right direction",
            presSubline1: "Since 6 years, GameWard was able to be distinguished as one of the most prominent esports clubs in France. Based on trust, intensity and boldness among other values, the team is committed, through its actions, to a more responsible and inclusive esports",
            presSubline2: "As the official Boulogne-Billancourt city esports club, GameWard offers one of the best performance ecosystems in Europe, which allows us to play at the highest french and international level in games such as League of Legends, Fortnite, or Trackmania.",
            presH2: "Our Partners",
    
            // PRODUCTION
            prodH3:"Latest videos",
            prodH2:"Thanks to a group of creative individuals, GameWard produces daily content for the fans.",
            prodP:"Browse through our video productions on Youtube. We also suggest you to follow our Twitch channel to know when we broadcast live.",
            prodCta:"Watch our videos",
    
            // LEGALES -  inside footer
            legalHeadline1: "- LEGAL INFORMATIONS",
            legal1a:"The website https://gameward.team/ is published and produced by SIGMA Esports SAS, registered at RCS (Paris Trade and Companies Register) on the 07-12-2017 under the SIREN number 833 830 250, and whose head office is located at 113 rue du Point du Jour 92100 Boulogne-Billancourt, France.",
            legal1b:"The website’s developer is Sisyphe Digital.",
            legal1c:"The website is hosted by Ionos.",
            legal1d:"Contact : contact@gameward.team",
            legalHeadline2:"- INTELLECTUAL PROPERTY",
            legal2a:"GameWard is a registered trademark of SIGMA Esports company.",
            legal2b:"Any reproduction of the GameWard brand and logo, the pictures and images found on the https://gameward.team/ and https://shop.gameward.team/ websites, partially or fully, is strictly forbidden. The products found in this catalog may be subject to change.",
            legalHeadline3:"- COOKIES AND TRACKERS",
            legal3a:"The website https://gameward.team/ has an audience measurement system.",
            legal3b:"In accordance with the European directive « Telecoms Package », Internet users must be informed and give their consent before placing trackers (more commonly known as « cookies »). Internet users must be given the choice to no be tracked when visiting a website or using an application.",
            legal3c:"Website publishers therefore have the obligation to seek prior consent from users. Refusing to install a cookie may make it impossible to access certain services. Users may however setup their internet browser to automatically refuse cookie installation.",
            legalHeadline4:"- AUDIENCE MEASUREMENT",
            legal4a:"Audience Measurement cookies are small files that allow the gathering and analyzing of traffic statistics on the website: pages visited, number of visits, bounce rate, origin of the visits… Audience measurement cookies are completely anonymous.",
            legal4b:"On this website, Google Analytics was chosen as our way to measure audience."
    
          })
        } else {
          this.setState({
            lang: "fr",
    
            // NAVIGATION
            navHome:"Accueil",
            navOverview:"Présentation",
            navResults: "Scores",
    
            // ACCUEIL
            accueilHeadline: "Premier club esports professionnel responsable",
            accueilSubline: "Marque esport française à mission, GameWard réunit joueurs professionnels et créateurs de contenus dans le but d’inspirer l’avenir",
            accueilTrophyCta: "Derniers résultats",

            // PRESENTATION
            presHeadline: "Accompagner l’esports dans la bonne direction",
            presSubline1: "Depuis 6 ans, le club GameWard s’est positionné comme l’une des références majeures en France. Basée sur des valeurs de confiance, d’intensité ou encore d’audace, l’équipe est engagée, à travers ses actions, pour un esport plus responsable et inclusif",
            presSubline2: "GameWard est aujourd’hui le club esports résident officiel de Boulogne-Billancourt et dispose notamment d’un des meilleurs écosystèmes de performance d’Europe, ce qui lui permet de jouer au plus haut niveau français et international sur des jeux comme League of Legends, Fortnite, ou aussi Trackmania.",
            presH2: "Nos partenaires",
    
            // PRODUCTION
            prodH3:"Dernières vidéos",
            prodH2:"Grâce à son équipe créative, GameWard produit du contenu au quotidien pour ses fans",
            prodP:"Parcourez l'ensemble de nos créations vidéos via notre chaîne Youtube. Nous vous invitons également à suivre notre chaîne Twitch afin de suivre nos retransmissions en direct.",
            prodCta:"Voir nos vidéos",
    
            // LEGALES -  inside footer
            legalHeadline1: "- INFORMATIONS LÉGALES",
            legal1a:"Le site https://gameward.team/ est édité et produit par la société SIGMA Esports SAS, immatriculée au RCS le 07-12-2017 sous le numéro SIREN 833 830 250 et dont le siège social est situé au 113 rue du Point du Jour 92100 Boulogne Billancourt.",
            legal1b:"Le développeur du site est la société Sisyphe Digital.",
            legal1c:"Le site est hébergé par la société Ionos.",
            legal1d:"Contact : contact@gameward.team",
            legalHeadline2:"- PROPRIÉTÉ INTELLECTUELLE",
            legal2a:"GameWard est une marque déposée de la société SIGMA Esports.",
            legal2b:"Toute reproduction de la marque et du logo GameWard, des photographies et des images du site https://gameward.team/ et https://shop.gameward.team/, partielle ou intégrale, est strictement interdite. Les produits présentés sur ce catalogue peuvent être amenés à subir des modifications.",
            legalHeadline3:"- COOKIES ET TRACEURS",
            legal3a:"Le site internet https://gameward.team/ possède un système de mesure d’audience.",
            legal3b:"En application de la directive européenne dite « paquet télécom », les internautes doivent être informés et donner leur consentement préalablement à l’insertion de traceurs (plus couramment appelés « cookies »). Les internautes doivent disposer d’une possibilité de choisir de ne pas être tracés lorsqu’ils visitent un site ou utilisent une application. Les éditeurs de sites internet ont donc l’obligation de solliciter au préalable le consentement des utilisateurs.",
            legal3c:"Le refus d’installation d’un cookie peut entraîner l’impossibilité d’accéder à certains services. L’internaute peut toutefois configurer son navigateur internet pour refuser l’installation des cookies.",
            legalHeadline4:"- MESURE D'AUDIENCE",
            legal4a:"Les cookies de mesure d’audience sont de petits fichiers qui permettent de connaitre et d’analyser les statistiques de trafic sur le site internet : les pages visitées, le nombre de visites, le taux de rebond, la provenance des visites,… Les cookies de mesure d’audience sont totalement anonymes.",
            legal4b:"Sur ce site, c’est la solution Google Analytics qui est utilisée pour mesurer l’audience."
          })
        }
      }
    
    render() {

        const handleRedirection = (url) => {
            window.open(url, '_blank');
        }

        return (
            <div className="device-container">

                <LangToggle
                    deviceClass="lang-toggle-mobile"
                    currentLang={this.state.lang}
                    action={()=>this.switchLang()}
                />
                
                <div className="device-header animate__animated animate__flipInX animate__delay_1S"></div>

                <div className="device-intro">
                    <h1 className="animate__animated animate__fadeInDown animate__delay-1s">{this.state.accueilHeadline}</h1>
                    <p className="animate__animated animate__fadeInDown animate__delay-2s">{this.state.accueilSubline}</p>
                </div>

                <div className="device-pres">
                    <h1>{this.state.presHeadline}</h1>

                    <p>{this.state.presSubline1}</p>
                    
                    <p>{this.state.presSubline2}</p>

                    <h1>{this.state.presH2}</h1>

                    <div className="device-partenaires-logos">    
                        <a href="https://www.boulognebillancourt.com/" target="_blank" rel="noopener noreferrer"><img src={LogoBoulogne} alt="Boulogne" /></a>
                        <a href="https://www.credit-agricole.fr/ca-paris/particulier.html" target="_blank" rel="noopener noreferrer"><img src={LogoCa} alt="CA idf" /></a>
                        <a href="https://www.honda.fr/" target="_blank" rel="noopener noreferrer"><img src={LogoHonda} alt="Honda France" /></a>
                        <a href="https://fr.turtlebeach.com/" target="_blank" rel="noopener noreferrer"><img src={LogoTurtleBeach} alt="Tutle Beach" /></a>
                        <a href="https://www.sodebo.com/" target="_blank" rel="noopener noreferrer"><img src={LogoSodebo} alt="Sodebo" /></a>
                    </div>
                </div>


                <div className="device-scores">

                    <h3>{this.state.accueilTrophyCta}</h3>

                    { // LOOP SCORES
                    this.state.data.map(elem =>

                    <div className="device-score-line" key={elem[0]}>
                        
                        <div className={"score-line-logo " + elem[1]}></div>

                        <div className="score-line-info"> 
                            <span className="date">{elem[2]}</span>
                            <span>{elem[3]}</span>
                            <span>{elem[4]}</span>
                        </div>

                        <div className={"score-line-top " + elem[6]}>
                            {/* <span className={elem[6]}>top</span> */}
                            <span className={elem[6]}>{elem[5]}</span>
                        </div>
                    </div>
                    )}
                </div>

                <div className="device-shop">

                    <h3>Shop</h3>
                    
                    <div className="merch-item item-2">
                       <div className="merch-cta" onClick={()=>handleRedirection('https://shop.gameward.team/collections/jerseys/products/maillot-officiel-gameward-2024')}>
                            <Cart className="icon" />
                        </div>
                    </div>

                    <div className="merch-item item-1"></div>


                    <div className="merch-item item-3"></div>

                    <div className="merch-item item-4"></div>

                    <div className="cta-shop">
                        <span onClick={()=>handleRedirection('https://shop.gameward.team/')}>Shop</span>
                    </div>

                </div>

                <div className="device-prod">
                    <h3>{this.state.prodH3}</h3>

                    <h2>{this.state.prodH2}</h2>

                    <p>{this.state.prodP}</p>

                    <div className="video">
                    <iframe src="https://www.youtube.com/embed/aoaZrVhPbug" title="aoaZrVhPbug" frameborder="0" allowfullscreen></iframe>
                    </div>

                    <div className="cta-video">
                        <span onClick={()=>handleRedirection('https://www.youtube.com/@GameWardTV')}>{this.state.prodCta}</span>
                    </div>
                </div>

                <div className="device-contact">
                    <div className="device-contact-logo"></div>
                    <p>113 rue du Point du Jour</p>
                    <p>92100 Boulogne-Billancourt</p>
                    <a href="mailto:contact@gameward.team">contact@gameward.team</a>
                </div>

                <div className="device-social">

                    <div className="icon-container">

                        <div className="icon-block">
                            <ReactSVG src={Discord} className="icon"
                                onClick={()=>handleRedirection('http://discord.gg/gameward')} />
                        </div>
                        <div className="icon-block">
                            <ReactSVG src={Facebook} className="icon" 
                                onClick={()=>handleRedirection('https://www.facebook.com/GameWardTeam/')} />
                        </div>
                        <div className="icon-block">
                            <ReactSVG src={Instagram} className="icon" 
                                onClick={()=>handleRedirection('https://www.instagram.com/gamewardteam/?hl=fr')} />
                        </div>
                        <div className="icon-block">
                            <ReactSVG src={Linkedin} className="icon" 
                                onClick={()=>handleRedirection('https://www.linkedin.com/company/gameward')} />
                        </div>
                        <div className="icon-block">
                            <ReactSVG src={Tiktok} className="icon" 
                                onClick={()=>handleRedirection('https://www.tiktok.com/@gameward.team')} />
                        </div>
                        <div className="icon-block">
                            <ReactSVG src={Twitch} className="icon" 
                                onClick={()=>handleRedirection('https://www.twitch.tv/gameward_tv')} />
                        </div>
                        <div className="icon-block">
                            <ReactSVG src={Twitter} className="icon" 
                                onClick={()=>handleRedirection('https://twitter.com/GameWardTeam')} />
                        </div>
                        <div className="icon-block">
                            <ReactSVG src={Youtube} className="icon" 
                                onClick={()=>handleRedirection('https://www.youtube.com/channel/UCbzGPPJEWpbk2cDk1s5fscw')} />
                        </div>
                        <div className="icon-block outline">
                            <ReactSVG src={Threads} className="icon" 
                                onClick={()=>handleRedirection('https://www.threads.net/@gamewardteam')} />
                        </div>

                    </div>

                </div>

                <div className="device-footer">
                    <div className="footer-tiny-logo"></div>
                    <p>v1.8.0 - &nbsp;&copy; 2025, GameWard - All rights reserved. All trademarks referenced here in are the properties of their respective owners.&nbsp;
                        <span onClick={this.show.bind(this)}>Mentions Légales</span>
                    </p>
                </div>

                <Rodal
                    measure={"%"}
                    height={80}
                    width={80}
                    showMask={false}
                    visible={this.state.visible} 
                    onClose={this.hide.bind(this)}>
                    
                    <div className="device-policy">
                        <h5>{this.state.legalHeadline1}</h5>

                        <p>{this.state.legal1a}</p>
                        <p>{this.state.legal1b}</p>
                        <p>{this.state.legal1c}</p>
                        <p>{this.state.legal1d}</p>

                        <h5>{this.state.legalHeadline2}</h5>

                        <p>{this.state.legal2a}</p>
                        <p>{this.state.legal2b}</p>

                        <h5>{this.state.legalHeadline3}</h5>

                        <p>{this.state.legal3a}</p>
                        <p>{this.state.legal3b}</p>
                        <p>{this.state.legal3c}</p>

                        <h5>{this.state.legalHeadline4}</h5>

                        <p>{this.state.legal4a}</p>
                        <p>{this.state.legal4b}</p>
                    </div>
                </Rodal>

            </div>
        )
    }
}
