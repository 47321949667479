import React, { Component, Fragment } from 'react'
import gsap from "gsap";
import { ScrollTrigger,  } from "gsap/ScrollTrigger";
import { TextPlugin } from "gsap/TextPlugin";
import { Download } from 'react-bytesize-icons';
import Social from './Social';
import Rodal from 'rodal';

// include styles
import 'rodal/lib/rodal.css';

export default class Footer extends Component {

    constructor(props) {
        super(props);
        this.state = { visible: false }; // RODAL BOX - Mentions Légales statement.
    }

    componentDidMount() {

        // GSAP Settings
        gsap.registerPlugin(ScrollTrigger, TextPlugin);
        gsap.config({ nullTargetWarn: false });
        gsap.defaults({
            ease: "Sin.easeIn"
        });
        // PLUGIN Settings
        ScrollTrigger.defaults({
            toggleActions: "play none none reverse", // DEFAULT DEHAVIOR
            markers: false // display helpers --
            //markers: {startColor: "blue", endColor: "pink", fontSize: "30px", indent: 10}
        });

        // PAGE TIMELINE
        gsap.timeline({
            scrollTrigger: {
              trigger: ".footer-container",
              start: "center bottom",
              end: "bottom center",
              scrub: false
            }
          })
          .to(".menu-info span", {duration: .1, text:{value:"Press &amp; Contact", padSpace:true} })
          .from(".contact-content", {y:-90, opacity: 0}, .2)
          .from(".press-content", {delay:.6, y:-40, opacity: 0}, .2)

    }

    show() {
        this.setState({ visible: true });
    }
     
    hide() {
        this.setState({ visible: false });
    }

    render() {

        const handleRedirection = (url) => {
            window.open(url, '_blank');
        }

        return (
            <Fragment>
            <div className="footer-container section-5">
                
                <div className="footer-contact">
                    <div className="contact-content">
                        <div className="footer-logo"></div>
                        <p>113 rue du Point du Jour</p>
                        <p>92100 Boulogne-Billancourt</p>
                        <a href="mailto:contact@gameward.team">contact@gameward.team</a>
                    </div>
                </div>

                <div className="footer-press">
                    <div className="press-content">
                        <h1>{this.props.footerH1}</h1>
                        <p>{this.props.footerP}</p>
                        <div className="press-cta" onClick={()=>handleRedirection('https://drive.google.com/drive/folders/1JBbyJEqz5t79gAPNeB2wReZqOWDChD7v')}>
                            <span>{this.props.footerCta}</span>
                            <div className="press-cta-icon">
                                <Download className="icon" />
                            </div>
                        </div>
                    </div>

                    <Social />

                </div>
                
            </div>
            
            <div className="footer-line">
                <div className="footer-tiny-logo"></div>
                <p>v1.8.0 - &nbsp;&copy; 2025, GameWard - All rights reserved. All trademarks referenced here in are the properties of their respective owners.</p>
                <p><span onClick={this.show.bind(this)}>Mentions Légales</span></p>
            </div>

                <Rodal
                    measure={"%"}
                    height={50}
                    width={70}
                    showMask={false}
                    visible={this.state.visible} 
                    onClose={this.hide.bind(this)}>
                    
                    <div className="policy">

                        <h5>{this.props.legalHeadline1}</h5>

                        <p>{this.props.legal1a}</p>
                        <p>{this.props.legal1b}</p>
                        <p>{this.props.legal1c}</p>
                        <p>{this.props.legal1d}</p>

                        <h5>{this.props.legalHeadline2}</h5>

                        <p>{this.props.legal2a}</p>
                        <p>{this.props.legal2b}</p>

                        <h5>{this.props.legalHeadline3}</h5>
                
                        <p>{this.props.legal3a}</p>
                        <p>{this.props.legal3b}</p>
                        <p>{this.props.legal3c}</p>

                        <h5>{this.props.legalHeadline4}</h5>
                
                        <p>{this.props.legal4a}</p>
                        <p>{this.props.legal4b}</p>
                    </div>
                </Rodal>
            </Fragment>
        )
    }
}
