import React, { Component } from 'react'
import gsap from "gsap";
import { ScrollTrigger,  } from "gsap/ScrollTrigger";
import { TextPlugin } from "gsap/TextPlugin";
import { Cart, Bag } from 'react-bytesize-icons';

import Team1 from '../assets/photos/gw_team_25_1.jpg'
import Team2 from '../assets/photos/gw_team_25_2.jpg'
import Team3 from '../assets/photos/gw_team_25_3.jpg'

export default class Merchandising extends Component {

    componentDidMount() {
        // GSAP Settings
        gsap.registerPlugin(ScrollTrigger, TextPlugin);
        gsap.config({ nullTargetWarn: false });
        gsap.defaults({
            ease: "Power0.easeNone"
        });
        // PLUGIN Settings
        ScrollTrigger.defaults({
            toggleActions: "play none none reverse", // DEFAULT DEHAVIOR
            scrub: false,
            markers: false
        });

        // PAGE TIMELINE
        gsap.timeline({
            scrollTrigger: {
                trigger: ".merch-container ",
                start: "center bottom",
                end: "top top",
                scrub: false
            }
            })
            .to(".menu-info span", {duration: .1, text:{value:"Shop", padSpace:true} })
            .to("header", {backgroundColor:"#F5F5F5"}, .15)
            .from(".merch-products-container", {y:45, opacity:0, delay: .2}, .25)
            .from(".merch-photos img", {delay: .7, y:45, x:10, opacity:0, rotation:6, stagger: .4}, .3)
            .from(".merch-headline", {x:90, opacity:0, delay: 2}, .35)
    }

    handleRedirection = (url) => {
        window.open(url, '_blank');
    }

    render() {

        return (
            <div className="merch-container section-3">

                <div className="merch-products-container">

                    <div className="merch-cta-shop" onClick={()=>this.handleRedirection('https://shop.gameward.team/')}>
                        <span>Shop</span>
                        <div className="merch-cta-shop-icon">
                            <Bag className="icon" />
                        </div>
                    </div>
                    
                    <div className="merch-item merch-small merch-pos-1"></div>

                    <div className="merch-item merch-big merch-pos-2">
                       <div className="merch-cta" onClick={()=>this.handleRedirection('https://shop.gameward.team/')}>
                            <Cart className="icon" />
                        </div>
                    </div>

                    <div className="merch-item merch-small merch-pos-3"></div>

                    <div className="merch-item merch-small merch-pos-4"></div>

                    <div className="merch-behind"></div>

                </div>

                <div className="merch-photos-container">

                    <div className="merch-photos">
                        <img src={Team1} alt="" className="photo-1"/>
                        <img src={Team2} alt="" className="photo-2"/>
                        <img src={Team3} alt="" className="photo-3"/>
                    </div>

                    <div className="merch-headline">
                        <h3>{this.props.merchText1}</h3>
                        <h3 className="strong">{this.props.merchText2}</h3>
                    </div>

                </div>

            </div>
        )
    }
}
