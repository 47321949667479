import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { TextPlugin } from "gsap/TextPlugin";
import React, { Component } from 'react';

// LOGOS ASSETS PNG
import LogoBoulogne from '../assets/png/logo_partenaires_boulogne.png';
import LogoCa from '../assets/png/logo_partenaires_ca.png';
import LogoSodebo from '../assets/png/logo_partenaires_sodebo.png';
import LogoHonda from '../assets/png/logo_partenaires_honda.png';
import LogoTurtleBeach from '../assets/png/logo_partenaires_tutlebeach.png';

export default class Presentation extends Component {

    componentDidMount = () => {

        // GSAP Settings
        gsap.registerPlugin(ScrollTrigger, TextPlugin);
        gsap.config({ nullTargetWarn: false });
        gsap.defaults({
            ease: "Sin.easeIn"
        });
        // PLUGIN Settings
        ScrollTrigger.defaults({
            toggleActions: "play none none reverse", // DEFAULT DEHAVIOR
            scrub: false,
            markers: false // display helpers --
            //markers: {startColor: "black", endColor: "red", fontSize: "30px", indent: 10}
        });

        // PAGE TIMELINE
        gsap.timeline({
            scrollTrigger: {
              trigger: ".pres-container",
              start: "center bottom",
              end: "top top",
              scrub: false
            }
          })
          .eventCallback("onStart", this.completeHandlerStart)
          .eventCallback("onReverseComplete", this.completeHandlerEnd)
          .to("header", {backgroundColor:"#F5F5F5"}, 0)
          .to(".pres-behind", { y: -90 }, .1)
          .from(".pres-container h1", { y: -45, opacity:0 }, .1)
          .from(".pres-container p", { y: -45, opacity:0 }, .15)
          .from(".pres-partenaires-infos", { opacity:0 }, .20)
          .from(".pres-container h2", { y: -45, opacity:0 }, .25)
          .from(".pres-partenaires-logos img", {rotation: 5, scale: .85, opacity: 0, stagger: 0.2}, .25);
    }

    completeHandlerStart = () => {
        gsap.to(".menu-info span", {duration: 0, text: this.props.navOverview, ease: "none"});
    }

    completeHandlerEnd = () => {
        gsap.to(".menu-info span", {duration: 0, text: this.props.navHome, ease: "none"});
    }

    render() {

        return (
            <div className="container">
                <div className="pres-container section-1">
                    <h1>{this.props.presHeadline}</h1>
                    <p>{this.props.presSubline1}</p>
                    <p>{this.props.presSubline2}</p>
                    <h2>{this.props.presH2}</h2>
                    <div className="pres-partenaires-logos">    
                        <a href="https://www.boulognebillancourt.com/" target="_blank" rel="noopener noreferrer"><img src={LogoBoulogne} alt="Boulogne" /></a>
                        <a href="https://www.credit-agricole.fr/ca-paris/particulier.html" target="_blank" rel="noopener noreferrer"><img src={LogoCa} alt="CA idf" /></a>
                        <a href="https://www.honda.fr/" target="_blank" rel="noopener noreferrer"><img src={LogoHonda} alt="Honda France" /></a>
                    </div>
                    <div className="pres-partenaires-logos marged">
                        <a href="https://fr.turtlebeach.com/" target="_blank" rel="noopener noreferrer"><img src={LogoTurtleBeach} alt="Tutle Beach" /></a>
                        <a href="https://www.sodebo.com/" target="_blank" rel="noopener noreferrer"><img src={LogoSodebo} alt="Sodebo" /></a>
                    </div>
                </div>

                <div className="pres-behind"></div>
                
            </div>
        )
    }
}
